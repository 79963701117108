var ApiUrls = /** @class */ (function () {
    function ApiUrls() {
    }
    ApiUrls.baseApiUrl = 'https://istar.io/api/v1.0/';
    ApiUrls.baseApiUrlAdmin = 'https://istar.io/';
    // Auth (EXAMPLE!)
    ApiUrls.auth = ApiUrls.baseApiUrl + 'auth/login';
    ApiUrls.register = ApiUrls.baseApiUrl + 'register';
    ApiUrls.logout = ApiUrls.baseApiUrl + 'logout';
    ApiUrls.reset = ApiUrls.baseApiUrl + 'user/password/reset';
    ApiUrls.newPassword = ApiUrls.baseApiUrlAdmin + 'admin/user/reset_password';
    return ApiUrls;
}());
export { ApiUrls };
