import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorHandler } from './http-error-handler.service';
import { ApiUrls } from '../../environments/apiUrls';
import { CookieService } from 'ngx-cookie';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./http-error-handler.service";
import * as i3 from "@angular/router";
import * as i4 from "ngx-cookie";
var AuthService = /** @class */ (function () {
    function AuthService(http, httpErrorHandler, router, route, cookieService) {
        this.http = http;
        this.router = router;
        this.route = route;
        this.cookieService = cookieService;
        this.permissions = {};
        this.myRolesSubject = new BehaviorSubject({});
        this.userInfoSubject = new BehaviorSubject({});
        this.handleError = httpErrorHandler.createHandleError('AuthService');
        this.myRoles$ = this.myRolesSubject.asObservable();
        this.userInfo$ = this.userInfoSubject.asObservable();
        // this.mockInit()
    }
    AuthService.prototype.getUserInfo = function (limits) {
        var _this = this;
        var user_id = this.cookieService.get('user_id') || '';
        if (!user_id) {
            return of(false);
        }
        var security_token = this.cookieService.get('security_token') || '';
        var options = {
            headers: new HttpHeaders().set('security_token', security_token || '').set('protocol_version', '2')
        };
        if (limits) {
            /* set limits queries */
        }
        return this.http.get(ApiUrls.baseApiUrl + "user/" + user_id + "/profile", options) // user/USER_ID/profile
            .pipe(tap(function (response) {
            // this.checkRoles(response.roles, response);
            // this.myRoles(this.permissions);
            _this.userInfo(response);
        }), catchError(this.handleError('init', '')));
    };
    AuthService.prototype.register = function (user, type) {
        var _this = this;
        if (type === void 0) { type = 'simple'; }
        if (type === 'simple') {
            user.profile_type = '1';
            // user.post_code = '1000';
            user.application_id = '1';
            user.gender = 'M';
            user.protocol_version = '2';
        }
        var formData = new FormData();
        Object.keys(user).forEach(function (key) {
            formData.append(key, user[key]);
        });
        var options = {
            headers: new HttpHeaders()
                .set('protocol_version', '2')
        };
        return this.http.post(ApiUrls.register, formData, options)
            .pipe(tap(function (resp) {
            resp.security_token && _this.cookieService.put('security_token', resp.security_token);
            resp.chat_access_token && _this.cookieService.put('chat_access_token', resp.chat_access_token);
            resp.user_id && _this.cookieService.put('user_id', resp.user_id);
            _this.router.navigateByUrl('/auth/signin');
        }), catchError(this.handleError('register', '')));
    };
    AuthService.prototype.login = function (loginData, platform) {
        var _this = this;
        if (platform === void 0) { platform = null; }
        loginData.application_id = environment.application_id;
        var security_token = this.cookieService.get('security_token') || '';
        var options = {
            headers: new HttpHeaders().set('security_token', security_token || '').set('protocol_version', '2')
        }; // does not set the header
        if (platform === 'facebook') {
            loginData = {
                'access_token': loginData.token,
                'application_id': environment.application_id,
            };
        }
        else if (platform === 'google') {
            loginData = {
                'user_token': loginData.token,
                'application_id': environment.application_id,
            };
        }
        var path = (platform) ? 'login/' + platform : 'login';
        return this.http.post("" + ApiUrls.baseApiUrl + path, loginData, options)
            .pipe(tap(function (resp) {
            _this.cookieService.put('security_token', resp.security_token);
            _this.cookieService.put('chat_access_token', resp.chat_access_token);
            _this.cookieService.put('user_id', resp.user_id);
        }));
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        var security_token = this.cookieService.get('security_token') || '';
        var data = {
            'security_token': security_token
        };
        var options = {
            headers: new HttpHeaders().set('security_token', security_token || '').set('protocol_version', '2')
        }; // does not set the header
        return this.http.post(ApiUrls.logout, data, options)
            .pipe(tap(function (res) {
            _this.userInfo(false);
            _this.cookieService.removeAll();
            _this.router.navigate(['']).then(function () {
                window.location.reload();
            });
        }), catchError(this.handleError('logout', '')));
    };
    AuthService.prototype.myRoles = function (data) {
        this.myRolesSubject.next(data);
    };
    AuthService.prototype.userInfo = function (data) {
        this.userInfoSubject.next(data);
    };
    AuthService.prototype.checkRoles = function (roles, user) { };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.HttpErrorHandler), i0.inject(i3.Router), i0.inject(i3.ActivatedRoute), i0.inject(i4.CookieService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
