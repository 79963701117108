import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';
var MainGuard = /** @class */ (function () {
    function MainGuard(cookieService, router) {
        this.cookieService = cookieService;
        this.router = router;
        this.subs = [];
    }
    MainGuard.prototype.canActivate = function (route, state) {
        // Pages without login
        var active = [
            state.url === '/' ? 1 : -1,
            state.url.split('/').indexOf('video-details'),
            state.url.split('/').indexOf('home'),
            state.url.split('/').indexOf('embed'),
            state.url.split('/').indexOf('terms'),
            state.url.split('/').indexOf('privacy'),
            state.url.split('/').indexOf('about'),
            state.url.split('/').indexOf('u'),
            state.url.split('?')[0].split('/').indexOf('search'),
            state.url.split('/').indexOf('channel'),
            state.url.split('?')[1] && state.url.split('?')[1].split("=").indexOf("token"),
            state.url.split('&')[1] && state.url.split('&')[1].split("=").indexOf("redirect"),
        ];
        if (active.find(function (el) { return el >= 0; }) >= 0 || this.cookieService.get('security_token') || '') {
            return true;
        }
        else {
            this.router.navigate(['/auth/signin']);
            return false;
        }
    };
    return MainGuard;
}());
export { MainGuard };
