import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from '../../_services/auth.service';
import { SpinerComponent } from '../../_components/spiner/spiner.component';
import { VideoPlayerComponent } from '../../_components/video-player/video-player.component';
import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { VgStreamingModule } from 'videogular2/streaming';
import { VideoPlayerEmbedComponent } from '../../_components/video-player-embed/video-player-embed.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        VgStreamingModule,
        
    ],
    providers: [
        AuthService
    ],
    declarations: [
        SpinerComponent,
        VideoPlayerComponent,
        VideoPlayerEmbedComponent
    ],
    entryComponents: [
    ],
    exports: [
        SpinerComponent,
        VideoPlayerComponent,
        VideoPlayerEmbedComponent
    ]
})

export class SharedModule { }
