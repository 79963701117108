import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { MainGuard } from './main.guard';
import { TermsComponent } from '../../_components/terms/terms.component';


const routes: Routes = [
    {
        path: '',
        loadChildren: '../main-content.module/main-content.module#MainContentModule',
    }, {
        path: 'embed',
        loadChildren: '../embed.module/embed.module#EmbedModule',
    }, {
        path: 'auth',
        loadChildren: '../authentication.module/authentication.module#AuthenticationModule',
    }, {
        path: 'terms',
        component: TermsComponent
    }, {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            initialNavigation: 'enabled'
        }),
    ],
    exports: [RouterModule],
    providers: [MainGuard]
})

export class AppRoutingModule {
}
