import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Subscription } from 'aws-sdk/clients/greengrass';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class MainGuard implements CanActivate {

    subs: Array<Subscription> = [];
    status: boolean;

    constructor(private cookieService: CookieService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // Pages without login
        const active = [
            state.url === '/' ? 1 : -1,
            state.url.split('/').indexOf('video-details'),
            state.url.split('/').indexOf('home'),
            state.url.split('/').indexOf('embed'),
            state.url.split('/').indexOf('terms'),
            state.url.split('/').indexOf('privacy'),
            state.url.split('/').indexOf('about'),
            state.url.split('/').indexOf('u'),
            state.url.split('?')[0].split('/').indexOf('search'),
            state.url.split('/').indexOf('channel'),
            state.url.split('?')[1] && state.url.split('?')[1].split("=").indexOf("token"),
            state.url.split('&')[1] && state.url.split('&')[1].split("=").indexOf("redirect"),
        ];
        if (active.find((el) => el >= 0) >= 0 || this.cookieService.get('security_token') || '') {
            return true;
        } else {
            this.router.navigate(['/auth/signin']);
            return false;
        }
    }
}
