import { Routes } from '@angular/router';
import { TermsComponent } from '../../_components/terms/terms.component';
var routes = [
    {
        path: '',
        loadChildren: '../main-content.module/main-content.module#MainContentModule',
    }, {
        path: 'embed',
        loadChildren: '../embed.module/embed.module#EmbedModule',
    }, {
        path: 'auth',
        loadChildren: '../authentication.module/authentication.module#AuthenticationModule',
    }, {
        path: 'terms',
        component: TermsComponent
    }, {
        path: '**',
        redirectTo: ''
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
