import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import 'hammerjs';
import * as __NgCli_bootstrap_1 from "./app/_modules/app.module/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
document.addEventListener('DOMContentLoaded', function () {
    document.body.style.visibility = 'visible';
    __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
        .catch(function (err) { return console.log(err); });
});
