import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AlertModule } from 'ngx-bootstrap';
import { AppComponent } from './app/app.component';
import { SharedModule } from '../shared.module/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieModule } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TermsComponent } from '../../_components/terms/terms.component';
import { MetaModule } from '@ngx-meta/core';
import { TransferHttpCacheModule } from '@nguniversal/common';

@NgModule({
    declarations: [
        AppComponent,
        TermsComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'server' }),
        BrowserAnimationsModule,
        TransferHttpCacheModule,
        SharedModule,
        AppRoutingModule,
        AlertModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            closeButton: true,
            progressBar: true,
        }),
        LoadingBarModule.forRoot(),
        CookieModule.forRoot(),
        MetaModule.forRoot(),
        BrowserTransferStateModule
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
