/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./terms.component";
var styles_TermsComponent = [i0.styles];
var RenderType_TermsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsComponent, data: {} });
export { RenderType_TermsComponent as RenderType_TermsComponent };
export function View_TermsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" terms works!\n"]))], null, null); }
export function View_TermsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-terms", [], null, null, null, View_TermsComponent_0, RenderType_TermsComponent)), i1.ɵdid(1, 114688, null, 0, i2.TermsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsComponentNgFactory = i1.ɵccf("app-terms", i2.TermsComponent, View_TermsComponent_Host_0, {}, {}, []);
export { TermsComponentNgFactory as TermsComponentNgFactory };
