export class ApiUrls {
    public static baseApiUrl = 'https://istar.io/api/v1.0/';
    public static baseApiUrlAdmin = 'https://istar.io/'; 

    // Auth (EXAMPLE!)
    public static auth = ApiUrls.baseApiUrl + 'auth/login';
    public static register = ApiUrls.baseApiUrl + 'register';
    public static logout = ApiUrls.baseApiUrl + 'logout';
    public static reset = ApiUrls.baseApiUrl + 'user/password/reset';
    public static newPassword = ApiUrls.baseApiUrlAdmin + 'admin/user/reset_password';
}
