import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppModule } from './app/_modules/app.module/app.module';

import 'hammerjs';

if (environment.production) {
    enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
    document.body.style.visibility = 'visible';
    
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));
});
