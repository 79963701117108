import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { MessageService } from './message.services';
import { ToastrService } from 'ngx-toastr';


/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError =
    <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable({
    providedIn: 'root'
})
export class HttpErrorHandler {
    constructor(private messageService: MessageService, private toastr: ToastrService) {
    }

    /** Create curried handleError function that already knows the service name */
    createHandleError = (serviceName = '') => <T>
    (operation = 'operation', result = {} as T) => this.handleError(serviceName, operation, result)

    /**
     * Returns a function that handles Http operation failures.
     * This error handler lets the app continue to run as if no error occurred.
     * @param serviceName = name of the data service that attempted the operation
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {

        return (error: HttpErrorResponse): Observable<T> => {
            // TODO: send the error to remote logging infrastructure

            try {
                if (error.error.error_msg) {
                    console.log(error.error.error_msg);
                    // this.toastr.warning(error.error.error_msg, 'Problem');
                } else if (error.status === 500) {
                    console.log('Server Problem: 500');
                    // this.toastr.warning('iStar servers cannot be reached at this time, please try again later', 'Problem'); // toastr log
                } else {
                    console.log(error.message);
                    // this.toastr.warning(error.message, 'Problem');
                }
            } catch (e) {}

            const message = (error.error instanceof ErrorEvent) ?
                error.error.message :
                `server returned code ${error.status} with body "${error.error}"`;

            this.messageService.add(`${serviceName}: ${operation} failed: ${message}`);

            return throwError(error);
        };

    }
}
