import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';

import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { ApiUrls } from '../../environments/apiUrls';
import { CookieService } from 'ngx-cookie';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { RegisterRequest } from '../_models/register.model';
import { LoginRequest } from '../_models/login.model';
import { LogoutRequest } from '../_models/logout.model';
import { UserResponse } from '../_models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    permissions = {};
    private handleError: HandleError;
    myRoles$: Observable<any>;
    private myRolesSubject = new BehaviorSubject<any>({});
    userInfo$: Observable<any>;
    userInfoSubject = new BehaviorSubject<any>({});
    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        private router: Router,
        private route: ActivatedRoute,
        private cookieService: CookieService) {
        this.handleError = httpErrorHandler.createHandleError('AuthService');
        this.myRoles$ = this.myRolesSubject.asObservable();
        this.userInfo$ = this.userInfoSubject.asObservable();
        // this.mockInit()
    }

    getUserInfo(limits?: { gallery_limit: number, image_limit: number, video_limit: number }): Observable<any> {
        const user_id = this.cookieService.get('user_id') || '';
        if (!user_id) {
            return of(false);
        }
        const security_token = this.cookieService.get('security_token') || '';

        const options = {
            headers: new HttpHeaders().set('security_token', security_token || '').set('protocol_version', '2')
        };
        if (limits) {
            /* set limits queries */
        }
        return this.http.get<any>(`${ApiUrls.baseApiUrl}user/${user_id}/profile`, options) // user/USER_ID/profile
            .pipe(
                tap(response => {
                    // this.checkRoles(response.roles, response);
                    // this.myRoles(this.permissions);
                    this.userInfo(response);
                }
                ),
                catchError(this.handleError('init', ''))
            );
    }

    register(user: any, type: string = 'simple'): Observable<any> {
        if (type === 'simple') {
            user.profile_type = '1';
            // user.post_code = '1000';
            user.application_id = '1';
            user.gender = 'M';
            user.protocol_version = '2';
        }

        const formData = new FormData();
        Object.keys(user).forEach(key => {
            formData.append(key, user[key]);
        });

        const options = {
            headers: new HttpHeaders()
                .set('protocol_version', '2')
        };

        return this.http.post<RegisterRequest>(ApiUrls.register, formData, options)
            .pipe(
                tap(resp => {
                    resp.security_token && this.cookieService.put('security_token', resp.security_token);
                    resp.chat_access_token && this.cookieService.put('chat_access_token', resp.chat_access_token);
                    resp.user_id && this.cookieService.put('user_id', resp.user_id);
                    this.router.navigateByUrl('/auth/signin');
                }),
                catchError(this.handleError('register', ''))
            );
    }

    login(loginData, platform: string = null): Observable<any> {
        loginData.application_id = environment.application_id;

        const security_token = this.cookieService.get('security_token') || '';

        const options = {
            headers: new HttpHeaders().set('security_token', security_token || '').set('protocol_version', '2')
        }; // does not set the header

        if (platform === 'facebook') {
            loginData = {
                'access_token': loginData.token,
                'application_id': environment.application_id,
            };
        } else if (platform === 'google') {
            loginData = {
                'user_token': loginData.token,
                'application_id': environment.application_id,
            };
        }

        const path = (platform) ? 'login/' + platform : 'login';

        return this.http.post<LoginRequest>(`${ApiUrls.baseApiUrl}${path}`, loginData, options)
            .pipe(
                tap(resp => {
                    this.cookieService.put('security_token', resp.security_token);
                    this.cookieService.put('chat_access_token', resp.chat_access_token);
                    this.cookieService.put('user_id', resp.user_id);
                })
            );
    }

    logout(): Observable<any> {
        const security_token = this.cookieService.get('security_token') || '';

        const data = {
            'security_token': security_token
        };

        const options = {
            headers: new HttpHeaders().set('security_token', security_token || '').set('protocol_version', '2')
        }; // does not set the header

        return this.http.post<LogoutRequest>(ApiUrls.logout, data, options)
            .pipe(
                tap(res => {
                    this.userInfo(false);
                    this.cookieService.removeAll();
                    this.router.navigate(['']).then(() => {
                        window.location.reload();
                    });
                }),
                catchError(this.handleError('logout', ''))
            );
    }

    myRoles(data) {
        this.myRolesSubject.next(data);
    }
    userInfo(data) {
        this.userInfoSubject.next(data);
    }

    checkRoles(roles, user) { }
}
