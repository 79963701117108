import { throwError } from 'rxjs';
import { MessageService } from './message.services';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "./message.services";
import * as i2 from "ngx-toastr";
/** Handles HttpClient errors */
var HttpErrorHandler = /** @class */ (function () {
    function HttpErrorHandler(messageService, toastr) {
        var _this = this;
        this.messageService = messageService;
        this.toastr = toastr;
        /** Create curried handleError function that already knows the service name */
        this.createHandleError = function (serviceName) {
            if (serviceName === void 0) { serviceName = ''; }
            return function (operation, result) {
                if (operation === void 0) { operation = 'operation'; }
                if (result === void 0) { result = {}; }
                return _this.handleError(serviceName, operation, result);
            };
        };
    }
    /**
     * Returns a function that handles Http operation failures.
     * This error handler lets the app continue to run as if no error occurred.
     * @param serviceName = name of the data service that attempted the operation
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    HttpErrorHandler.prototype.handleError = function (serviceName, operation, result) {
        var _this = this;
        if (serviceName === void 0) { serviceName = ''; }
        if (operation === void 0) { operation = 'operation'; }
        if (result === void 0) { result = {}; }
        return function (error) {
            // TODO: send the error to remote logging infrastructure
            try {
                if (error.error.error_msg) {
                    console.log(error.error.error_msg);
                    // this.toastr.warning(error.error.error_msg, 'Problem');
                }
                else if (error.status === 500) {
                    console.log('Server Problem: 500');
                    // this.toastr.warning('iStar servers cannot be reached at this time, please try again later', 'Problem'); // toastr log
                }
                else {
                    console.log(error.message);
                    // this.toastr.warning(error.message, 'Problem');
                }
            }
            catch (e) { }
            var message = (error.error instanceof ErrorEvent) ?
                error.error.message :
                "server returned code " + error.status + " with body \"" + error.error + "\"";
            _this.messageService.add(serviceName + ": " + operation + " failed: " + message);
            return throwError(error);
        };
    };
    HttpErrorHandler.ngInjectableDef = i0.defineInjectable({ factory: function HttpErrorHandler_Factory() { return new HttpErrorHandler(i0.inject(i1.MessageService), i0.inject(i2.ToastrService)); }, token: HttpErrorHandler, providedIn: "root" });
    return HttpErrorHandler;
}());
export { HttpErrorHandler };
